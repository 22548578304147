import { ComponentChildren } from 'preact';
import { ForwardedRef, forwardRef, MutableRefObject, useEffect, useRef } from 'preact/compat';
import Gap, { GapYClass } from 'api/style/Gap.ts';
import RequestMethod from 'api/request/RequestMethod.ts';
import Arrayify from 'api/types/Arrayify.ts';
import Booleanify from 'api/types/Booleanify.ts';
import EmailChecker from 'api/email/EmailChecker.ts';
import Promiseable from 'api/types/Promiseable.ts';
import Width, { WidthClass } from 'api/style/Width.ts';
import Height, { HeightClass } from 'api/style/Height.ts';
import MaybeSignal, { isSignal, Unsignalify } from 'api/types/MaybeSignal.ts';
import { computed, effect, useSignal } from '@preact/signals';

export interface FormProps {
    method?: RequestMethod.GET | RequestMethod.POST;
    action: string;
    newtab?: boolean | 'true' | 'false';
    goto?: string | ({ path: string } & RequestInit);

    // deno-lint-ignore no-explicit-any
    onSubmit?: () => any;
    isFunc?: boolean;
    disabled?: MaybeSignal<boolean | 'true' | 'false'>;

    children?: MaybeSignal<ComponentChildren>;

    className?: string;
    width?: Width;
    height?: Height;
    spaceY?: Gap;
}

const Form = forwardRef(
    function (
        props: FormProps,
        ref?: ForwardedRef<HTMLFormElement>,
    ) {
        props.method ??= RequestMethod.GET;
        ref ??= useRef<HTMLFormElement>(null);

        const goto = () => {
            if (props.isFunc) {
                return;
            }

            (async () => {
                const r = await fetch(
                    new URL(
                        typeof props.goto == 'string' ? props.goto : props.goto!.path,
                        location.origin,
                    )
                        .toString(),
                    {
                        ...(typeof props.goto == 'string' ? {} : props.goto),
                    },
                );

                if (!r.ok) {
                    throw new Error(
                        'The server responded with an error.',
                    );
                }

                location.reload();
            })();
        };

        const disabled = () => {
            const form = (ref as MutableRefObject<HTMLFormElement>).current;
            if (!form) {
                return true;
            }

            const disabled = Booleanify(props.disabled);
            const formData = new FormData(form);

            const radioGroups = new Map<string, HTMLInputElement[]>();

            let required = true;
            form.querySelectorAll('input').forEach((x) => {
                if (!required) return;

                if (x.type == 'radio') {
                    if (!radioGroups.has(x.name)) {
                        radioGroups.set(x.name, []);
                    }

                    radioGroups.get(x.name)!.push(x);

                    return;
                }

                if (x.type == 'checkbox' && x.required && !x.checked) {
                    return required = false;
                } else if (
                    x.required && (x.value.trim().length == 0)
                ) {
                    return required = false;
                }

                formData.set(x.name, x.value.trim());

                if (x.type == 'email' && !EmailChecker.valid(x.value.trim())) {
                    return required = false;
                }

                if (
                    x.type == 'password' && x.name.endsWith('_confirm') &&
                    ((form.querySelector(
                            `input[name="${x.name.slice(0, -'_confirm'.length)}"]`,
                        ) as HTMLInputElement | null)?.value ?? '').trim() !=
                        x.value.trim()
                ) {
                    return required = false;
                }

                if (Booleanify(x.getAttribute('data-exists'))) {
                    return required = false;
                }

                if (x.getAttribute('data-value')) {
                    formData.set(
                        x.name,
                        x.getAttribute('data-value') ?? '[]',
                    );
                }

                if (
                    x.getAttribute('data-required') &&
                    (Number.parseInt(
                            x.getAttribute('data-required') ??
                                'Infinity',
                        ) ?? Infinity) > Arrayify(
                            JSON.parse(
                                formData.get(x.name) as string,
                            ) ?? [],
                        )!.length
                ) {
                    return required = false;
                }
            });

            radioGroups.forEach((group, groupName) => {
                if (!required) return;

                if (!group.some((radio) => radio.checked)) {
                    required = false;
                }

                group.forEach((radio) => {
                    if (radio.checked) {
                        formData.set(radio.name, radio.value);
                    }
                });
            });

            const d = disabled || !required;

            if (d) {
                return true;
            }

            for (const [key, value] of formData.entries()) {
                formData.set(key, value.toString().trim());
            }

            return false;
        };

        const bs = [] as HTMLButtonElement[];

        const findSubmitButtons = (
            e: HTMLElement = (ref as MutableRefObject<HTMLFormElement>).current,
        ) => {
            if (!e) {
                return;
            }

            if (
                e.tagName == 'BUTTON' &&
                (e as HTMLButtonElement).type == 'submit'
            ) {
                const b = e as HTMLButtonElement;

                if (bs.some((x) => b.isSameNode(x))) {
                    return;
                }

                bs.push(b);

                const f = () => b.disabled = disabled();
                fs.push(f);
                (ref as MutableRefObject<HTMLFormElement>).current!.addEventListener('input', f);

                return;
            }

            for (const child of e.children) {
                findSubmitButtons(child as HTMLElement);
            }
        };

        const fs: (() => boolean)[] = [];

        const buttons = () => {
            const form = (ref as MutableRefObject<HTMLFormElement>).current;

            if (!form) {
                return false;
            }

            const d = disabled();

            findSubmitButtons();
            bs.forEach((b) => {
                b.disabled = d;
            });

            return !d;
        };

        useEffect(() => {
            const form = (ref as MutableRefObject<HTMLFormElement>).current;
            if (!form) {
                return;
            }

            disabled();

            if (props.goto) {
                form.addEventListener('submit', goto, { once: true });
            }

            buttons();

            return () => {
                if (props.goto) {
                    form.removeEventListener('submit', goto);
                }

                fs.forEach((f) => form.removeEventListener('input', f));
            };
        }, [(ref as MutableRefObject<HTMLFormElement>).current]);

        effect(buttons);

        return (
            <form
                target={Booleanify(props.newtab) ? '_blank' : undefined}
                method={props.method}
                action={props.action}
                ref={ref}
                onSubmit={() => {
                    props.onSubmit?.();
                    return !disabled();
                }}
                className={`relative flex-grow shrink-0 flex flex-col justify-items-center ${
                    WidthClass(props.width ?? 'full')
                } ${HeightClass(props.height ?? 'full')} ${GapYClass(props.spaceY ?? 8)} ${
                    (props.className ?? '').split(' ').filter((x) => x.trim().length > 0).map((x) => `!${x.trim()}`)
                        .join(' ')
                }`}
            >
                {...(Arrayify(Unsignalify(props.children)) ?? []).map((x) => Unsignalify(x))}
            </form>
        );
    },
);

export const GETForm = forwardRef(
    function (
        props: Omit<FormProps, 'method'>,
        ref?: ForwardedRef<HTMLFormElement>,
    ) {
        return <Form {...props} ref={ref} method={RequestMethod.GET} />;
    },
);

export const GetForm = GETForm;

export const POSTForm = forwardRef(
    function (
        props: Omit<FormProps, 'method'>,
        ref?: ForwardedRef<HTMLFormElement>,
    ) {
        return <Form {...props} ref={ref} method={RequestMethod.POST} />;
    },
);

export const PostForm = POSTForm;

export const FuncForm = forwardRef(
    function (
        props: Omit<Omit<FormProps, 'method'>, 'action'> & {
            // deno-lint-ignore no-explicit-any
            onSubmit: () => Promiseable<any>;
        },
        ref?: ForwardedRef<HTMLFormElement>,
    ) {
        return (
            <Form
                {...props}
                ref={ref}
                action='javascript:void(0);'
                isFunc
            />
        );
    },
);

export default Form;
